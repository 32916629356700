import { AppProvider } from '../contexts/App';
import { AccountProvider } from '../contexts/Account';
import { OrderProvider } from '../contexts/Order';

export default function Providers({ children }) {
  return <AppProvider>
    <AccountProvider>
      <OrderProvider>
        {children}
      </OrderProvider>
    </AccountProvider>
  </AppProvider>;
}
