import Request from '../system/Request';

const GovUK = {
  getBankHolidays: () => {
    return new Promise((resolve, reject) => {
      (new Request()).get('/api/bank-holidays/get', {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      })
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  }
};

export default GovUK;
