class Order {
  cartId = 'new';
  version = 1.1;
  items = [];
  subTotal = 0;
  firstName = '';
  lastName = '';
  email = '';
  phone = '';
  mobile = '';
  paymentFirstName = '';
  paymentLastName = '';
  paymentCompany = '';
  paymentAddressLine1 = '';
  paymentAddressLine2 = '';
  paymentAddressLine3 = '';
  paymentCityTown = '';
  paymentCounty = '';
  paymentPostCode = '';
  paymentCountry = '';
  paymentMethod = '';
  shippingFirstName = '';
  shippingLastName = '';
  shippingCompany = '';
  shippingAddressLine1 = '';
  shippingAddressLine2 = '';
  shippingAddressLine3 = '';
  shippingCityTown = '';
  shippingCounty = '';
  shippingPostCode = '';
  shippingCountry = '';
  shippingMethod = '';
  comment = '';
  tracking = '';
  shippingTotal = '';
  total = '';
  tax = '';
  status = '';

  getVersion = () => {
    return this.version ?? 0;
  }

  setCartId = (cartId) => {
    this.cartId = cartId;
  }

  getCartId  = () => {
    return this.cartId;
  }

  setItems = (state) => {
    this.items = state;
  }

  addItem = (product) => {
    this.items.push(product);
  }

  getItems = () => {
    return this.items;
  }

  setSubTotal = (subTotal) => {
    this.subTotal = subTotal;
  }

  getSubTotal = () => {
    return this.subTotal;
  }

  setShippingTotal = (shippingTotal) => {
    this.shippingTotal = shippingTotal;
  }

  getShippingTotal = () => {
    return this.shippingTotal;
  }

  setTotal = (total) => {
    this.total = total;
  }

  getTotal = () => {
    return this.total;
  }

  setTax = (tax) => {
    this.tax = tax;
  }

  getTax = () => {
    return this.tax;
  }
}

export default Order;
