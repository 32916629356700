import React, { useState } from 'react';
import FontAwesomeIcon from '../../components/FontAwesomeIcon';
import Login from '../../components/Login';
import Dialog from '../';

import styles from './styles.module.scss';

const LoginDialog = ({ closeDialog }) => {
  const [requiresTwoStep, setRequireTwoStep] = useState(false);

  return <Dialog title={<>
    <FontAwesomeIcon icon={requiresTwoStep ? 'clock' : 'sign-in'} className="me-2" />
    {requiresTwoStep ? 'Authentication Code' : 'Login'}
  </>} closeDialog={closeDialog}>
    <div className={styles.dialog}>
      <Login onRequireTwoStep={() => setRequireTwoStep(true)} />
    </div>
  </Dialog>;
}

export default LoginDialog;
