import Request from '../system/Request';

const Category = {
  getMenuCategories: () => {
    return new Promise((resolve, reject) => {
      (new Request()).get('/api/category/menu', {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      })
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },

  getBySlug: (data) => {
    return new Promise((resolve, reject) => {
      (new Request()).get(`/api/category/slug/${data.slug}`, {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      })
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },
};

export default Category;
