import React from 'react';
import { Button, Container, Navbar, Nav, Offcanvas, Image, Dropdown, Badge, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAccountContext } from '../../contexts/Account';
import { useOrderContext } from '../../contexts/Order';
import FontAwesomeIcon from '../../components/FontAwesomeIcon';
import InitAccountStateData from "../../data/InitAccountStateData";

const MobileMenuSidebar = ({ categories, openSideBar = false, onCloseSideBar, openLogin, openRegister }) => {
  const { orderState } = useOrderContext();
  const { accountState, isAuthenticated, dispatchAccount } = useAccountContext();

  const onCall = () => {
    window.location.href = 'tel:+442083866960';
  };

  const onLogout = () => {
    dispatchAccount({ ...InitAccountStateData });
  };

  return <>
    <Navbar key={openSideBar} expanded={openSideBar} onToggle={onCloseSideBar} className="visually-hidden bg-body-tertiary mb-3">
      <Container fluid className={'h-100 mh-100'}>
        <Navbar.Brand></Navbar.Brand>
        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${openSideBar}`} />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-${openSideBar}`}
          aria-labelledby={`offcanvasNavbarLabel-expand-${openSideBar}`}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${openSideBar}`}>
              <Image src={process.env.REACT_APP_HOST_CDN + '/images/logo.png'} height={50} alt="Wholesale Packaging" />
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3">
              <Button
                as={Link}
                variant={'light'}
                to="/"
                onClick={() => onCloseSideBar()}
                className={'mb-2 text-start'}
              >
                <FontAwesomeIcon icon={'home'} className={'text-secondary me-2'} />
                Home
              </Button>
              <Dropdown navbar={true} autoClose={true} className={'w-100 mb-2'}>
                <Dropdown.Toggle variant="light" className={'w-100 text-start'}>
                  <FontAwesomeIcon icon={'layer-group'} className={'text-secondary me-2'} />
                  Categories
                </Dropdown.Toggle>
                <Dropdown.Menu className={'bg-light p-2 mt-xl-3'}>
                  {0 < categories.length ? categories.map((parent, pIdx) => <Nav.Link
                    key={pIdx}
                    as={Link}
                    to={`/c/${parent.slug}`}
                    onClick={() => onCloseSideBar()}
                    className={pIdx === categories.length - 1 ? '' : 'border-bottom'}
                  >
                    <FontAwesomeIcon icon={'chevron-right'} className={'text-secondary me-2'} />
                    {parent.name}
                  </Nav.Link>) : ''}
                </Dropdown.Menu>
              </Dropdown>
              <Button
                as={Link}
                variant={'light'}
                to="/c/clearance-lines"
                onClick={() => onCloseSideBar()}
                className={'mb-2 text-start text-danger'}
              >
                <FontAwesomeIcon icon={'percentage'} className={'me-2'} />
                Clearance
              </Button>
              <Button
                as={Link}
                variant={'light'}
                to="/contact"
                onClick={() => onCloseSideBar()}
                className={'mb-2 text-start'}
              >
                <FontAwesomeIcon icon={'envelope'} className={'text-secondary me-2'} />
                Contact
              </Button>
              <Dropdown navbar={true} autoClose={true} className={'w-100 mb-2'}>
                <Dropdown.Toggle variant="light" className={'w-100 text-start'}>
                  <FontAwesomeIcon icon={'user-circle'} className={'text-secondary me-2'} />
                  My Account
                </Dropdown.Toggle>
                <Dropdown.Menu align={'end'}>
                  {isAuthenticated ? <>
                    <Dropdown.Item as={Link} to={'/account'} className={'fs-7'}>
                      <FontAwesomeIcon icon={'tachometer-alt'} className={'me-2'} />
                      Dashboard
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to={'/account/orders'} className={'fs-7'}>
                      <FontAwesomeIcon icon={'box-alt'} className={'me-2'} />
                      My Orders
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to={'/account/wishlist'} className={'d-flex fs-7'}>
                      <FontAwesomeIcon icon={'book-heart'} className={'me-2 align-self-center'} />
                      My Wishlist
                      <Badge
                        bg={isAuthenticated && 0 < accountState.user.wishlist.length ? 'primary' : 'grey'}
                        className={'ms-auto align-self-center'}
                      >
                        {isAuthenticated ? accountState.user.wishlist.length : ''}
                      </Badge>
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to={'/account/addresses'} className={'fs-7'}>
                      <FontAwesomeIcon icon={'address-card'} className={'me-2'} />
                      Manage Addresses
                    </Dropdown.Item>
                    <Dropdown.Divider className={'border-bottom'} />
                    <Dropdown.Item onClick={onLogout} className={'fs-7'}>
                      <FontAwesomeIcon icon={'address-card'} className={'me-2'} />
                      Logout
                    </Dropdown.Item>
                  </> : <>
                    <Dropdown.Item onClick={() => openLogin()} className={'fs-7'}>
                      <FontAwesomeIcon icon={'sign-in'} className={'text-secondary me-2'} />
                      Login
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => openRegister()} className={'fs-7'}>
                      <FontAwesomeIcon icon={'edit'} className={'text-secondary me-2'} />
                      Create account
                    </Dropdown.Item>
                  </>}
                </Dropdown.Menu>
              </Dropdown>
              <div className={'my-3'}>
                <Row>
                  <Col>
                    <Nav.Item className={'fs-9 text-center black-text w-100 d-inline-block'}>
                      <Button
                        as={Link}
                        to={'/search'}
                        size={'sm'}
                        variant="light"
                        className={'position-relative w-100 py-2 fs-9'}
                        onClick={() => onCloseSideBar()}
                      >
                        <FontAwesomeIcon icon={'search'} className={'text-secondary fs-3 d-block'} />
                        Search
                      </Button>
                    </Nav.Item>
                  </Col>
                  <Col>
                    <Nav.Item className={'fs-9 d-inline-block w-100 text-center black-text'}>
                      <Button
                        as={Link}
                        to={isAuthenticated ? '/account/notifications' : '/login'}
                        size={'sm'}
                        variant="light"
                        className={'position-relative w-100 py-2 fs-9'}
                        onClick={() => onCloseSideBar()}
                      >
                        <Badge bg={isAuthenticated && 0 < accountState?.user?.notifications.length ? 'danger' : 'secondary'} className={'position-absolute mt-2 ms-2'}>
                          {isAuthenticated && accountState?.user?.notifications.length ? accountState?.user?.notifications.length : 0}
                        </Badge>
                        <FontAwesomeIcon icon={'bell'} className={'text-secondary fs-3 d-block'} />
                        Notifications
                      </Button>
                    </Nav.Item>
                  </Col>
                  <Col>
                    <Nav.Item className={'fs-9 d-inline-block w-100 text-center black-text'}>
                      <Button
                        as={Link}
                        to={'/cart'}
                        size={'sm'}
                        variant="light"
                        className={'position-relative w-100 py-2 fs-9'}
                        onClick={() => onCloseSideBar()}
                      >
                        <FontAwesomeIcon icon={'shopping-cart'} className={'text-secondary fs-3 d-block'} />
                        <Badge bg={0 < orderState?.items.length ? 'danger' : 'secondary'} className={'position-absolute mt-1 ms-4'}>
                          {orderState?.items?.length ? orderState?.items?.reduce((a,v) =>  a = a + v.quantity , 0 ) : 0}
                        </Badge>
                        £{(orderState?.total / 100).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                      </Button>
                    </Nav.Item>
                  </Col>
                </Row>
              </div>
              <Button onClick={() => onCall()} size={'sm'} className={'light mt-2 text-white'}>
                <FontAwesomeIcon icon={'phone me-2'}/>
                <span><b>Call</b></span>
                <Badge bg={'tertiary'} className="text-primary fs-9 ms-2">
                  +44(0)208 386 6960
                </Badge>
              </Button>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  </>;
};

export default MobileMenuSidebar;
