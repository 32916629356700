import Request from '../system/Request';

const Product = {
  getBySlug: (data) => {
    return new Promise((resolve, reject) => {
      (new Request()).get(`/api/product/slug/${data.slug}`, {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      })
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },
};

export default Product;
