import React from 'react';
import FontAwesomeIcon from '../../../FontAwesomeIcon';
import TextField from '../_Types/TextField';

const PasswordField = ({
  id = 'password',
  label = 'Password',
  placeholder = 'Create a password',
  value,
  updateField,
  errorFields,
  onSubmit = () => {},
}) => {
  return <fieldset>
    <label htmlFor={id} className="text-primary mb-1">
      <FontAwesomeIcon icon="lock" className="me-2"/>
      {label}
    </label>
    <TextField
      id={id}
      type={'password'}
      placeholder={placeholder}
      value={value}
      onChange={updateField}
      error={0 < errorFields.filter(field => id === field.key).length}
      autoComplete={'off'}
      required={true}
      onSubmit={onSubmit}
    />
    {0 < errorFields.filter(field => id === field.key).length ? <div className={'fs-8 red-text'}>
      <FontAwesomeIcon icon={'exclamation-triangle'} className={'me-2'}/>
      {errorFields.filter(field => id === field.key)[0].message}
    </div> : ''}
  </fieldset>;
}

export default PasswordField;
