import React, { createContext, useContext, useState } from 'react';
import Cache from '../system/Cache';
import StateNormalizer from '../system/StateNormalizer';
import AppClass from '../class/App.class';

export const AppContext = createContext({
  state: new AppClass(),
  dispatch: () => null
});

export const AppProvider = ({ children }) => {
  const cache = new Cache();
  const defaultApp = new AppClass();
  const stateNormalizer = new StateNormalizer();

  const normalizeData = (key, classObj) => {
    // Get data from cache
    const dataFromCache = cache.get(`wholesale_packaging__${key}`);
    if (dataFromCache) {
      // Normalize data into class
      return stateNormalizer.normalize(classObj, dataFromCache);
    }
    return classObj;
  };

  const [appState, setAppState] = useState(() => {
    const localAppState = cache.get('wholesale_packaging__app');

    if (null === localAppState) {
      return defaultApp;
    }

    const app = normalizeData('app', new AppClass());
    if (defaultApp.getVersion() !== app.getVersion()) {
      return defaultApp;
    }

    return app;
  });

  const dispatchApp = (appState) => {
    // Clear keys that are not needed to be cached for app
    cache.set('wholesale_packaging__app', { ...appState });

    setAppState(appState);
  };

  return <AppContext.Provider value={{ appState, dispatchApp }}>
    { children }
  </AppContext.Provider>;
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContent must be used within an AppProvider');
  }

  return context;
};
