import React from 'react';
import FontAwesomeIcon from '../../../FontAwesomeIcon';
import TextField from '../_Types/TextField';

const LastNameField = ({
  data,
  updateField,
  errorFields,
  onSubmit = () => {}
}) => {
  return <fieldset>
    <label htmlFor="lastName" className="text-primary mb-1">
      <FontAwesomeIcon icon="user" className="me-2"/>
      Last name
    </label>
    <TextField
      id={'lastName'}
      placeholder={'Enter your last name'}
      value={data.lastName}
      onChange={updateField}
      required={true}
      autoComplete={'off'}
      error={0 < errorFields.filter(field => 'lastName' === field.key).length}
      onSubmit={onSubmit}
    />
    {0 < errorFields.filter(field => 'lastName' === field.key).length ? <div className={'fs-8 red-text'}>
      <FontAwesomeIcon icon={'exclamation-triangle'} className={'me-2'}/>
      {errorFields.filter(field => 'lastName' === field.key)[0].message}
    </div> : ''}
  </fieldset>;
};

export default LastNameField;
