import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Button, Col, FormCheck, Row } from 'react-bootstrap';
import { useAppContext } from '../../contexts/App';
import { useAccountContext } from '../../contexts/Account';
import FontAwesomeIcon from '../../components/FontAwesomeIcon';
import CodeInput from '../../components/CodeInput';
import Cache from '../../system/Cache';
import EmailField from '../Form/Fields/EmailField';
import PasswordField from '../Form/Fields/PasswordField';
import Api from '../../Api';

import styles from './styles.module.scss';

const Login = ({ closeDialog, onRequireTwoStep }) => {
  const { appState, dispatchApp } = useAppContext();
  const { accountState, dispatchAccount } = useAccountContext();
  const cache = new Cache();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(() => {
    let cacheUsername = '';
    const rememberMe = cache.get('remember_me');
    if (null !== rememberMe) {
      cacheUsername = rememberMe;
    }

    return {
      email: cacheUsername,
      password: '',
      two_step: {
        0: '',
        1: '',
        2: '',
        3: '',
        4: '',
        5: ''
      },
      remember_me: null !== rememberMe
    };
  });
  const [errorFields, setErrorFields] = useState([]);
  const [requiresTwoStep, setRequiresTwoStep] = useState(false);

  const requireTwoStep = () => {
    setRequiresTwoStep(true);
    onRequireTwoStep();
  };

  const openRegister = () => {
    appState.setDialogState('login', false);
    appState.setDialogState('register', true);
    dispatchApp({ ...appState });
  };

  const openForgotPassword = () => {
    appState.setDialogState('login', false);
    appState.setDialogState('recover', true);
    dispatchApp({ ...appState });
  };

  const validateFields = () => new Promise((resolve, reject) => {
    Promise.all(Object.keys(data).map((key) => {
      return validateField(key, data[key]).then(() => true).catch(() => false);
    })).then((result) => result.includes(false) ? reject() : resolve());
  });

  const validateField = (field, value) => new Promise((resolve, reject) => {
    switch (field) {
      case 'email':
        if (String(value).toLowerCase().match(
          /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
        )) {
          resolve();
        } else {
          errorFields.push({
            key: 'email',
            message: 'Please enter a valid email address.'
          });
          setErrorFields(errorFields);
          reject();
          return;
        }
        resolve();
        break;
      case 'password':
        if (value.length < 8) {
          errorFields.push({
            key: 'password',
            message: 'Password must be a minimum of 8 characters.'
          });
          setErrorFields(errorFields);
          reject();
          return;
        }
        if (String(value).match(
          /^[A-Za-z,.\-_£$!@%^*_+={}\]\]:/]$/
        )) {
          errorFields.push({
            key: 'password',
            message: 'Password contains invalid characters.'
          });
          setErrorFields(errorFields);
          reject();
          return;
        }
        resolve();
        break;
      case 'two_step':
        if (!requiresTwoStep) {
          resolve();
          return;
        }

        Promise.all(Object.keys(data.two_step).map((key) => {
          return data.two_step[key].match(/^[0-9]$/) ? true : false;
        })).then((result) => {
          if (result.includes(false)) {
            setErrorFields([{
              key: 'two_step',
              message: 'Invalid two step code.'
            }]);
            reject();
            return;
          }

          resolve();
        });

        break;
      default:
        // remember me can be auto validated
        resolve();
        break;
    }
  });

  const updateField = (id, value) => {
    const newData = { ...data };
    newData[id] = value;

    setData(newData);
  };

  const updateTwoStep = (index, code) => {
    const newData = { ...data };
    newData.two_step[index] = code;

    setData(newData);
    setErrorFields([]);
  }

  const toggleRememberMe = () => {
    const newData = { ...data };
    newData['remember_me'] = !data.remember_me;
    setData(newData);
  };

  const submit = () => {
    setLoading(true);
    validateFields().then(() => {
      Api.Account.login(data, requiresTwoStep).then((response) => {
        setLoading(false);
        if (response.token) {
          const accountData = {
            ...accountState,
            token: response.token,
            refresh_token: response.refresh_token
          };

          dispatchAccount(accountData);

          if (data.remember_me) {
            cache.set('remember_me', data.email);
          }

          Api.Account.get().then((user) => {
            dispatchAccount({
              ...accountData,
              user: user
            });

            if (appState.getDialogState('login')) {
              appState.setDialogState('login', false);

              dispatchApp({ ...appState });
            }
          }).catch((e) => {
            setLoading(false);
            setErrorFields([{
              key: 'login',
              message: 'Failed to get account information, if this persists please contact support.'
            }])
          });
        } else if ('two_step' === response.data.field && response.data.required) {
          requireTwoStep(true);
          setErrorFields([]);
        } else {
          setErrorFields([{
            key: response.data.field,
            message: response.message
          }]);
        }
      }).catch((e) => {
        setLoading(false);
        setErrorFields([{
          key: 'login',
          message: 'An error occurred, please try again later!'
        }])
        console.log('error', e);
      });
    }).catch((e) => {
      setLoading(false);
    });
  };

  useEffect(() => {
    if (null === accountState.user) return;

    navigate(null === accountState.user.profileUpdatedAt ? '/profile' : '/');
  }, [accountState, navigate]);

  return <div className={styles.dialog}>
    <div id={'login_dialog'}>
      {requiresTwoStep ? <Row>
        <Col xs={12}>
          <label htmlFor="two_step" className="mb-2">
            <FontAwesomeIcon icon="clock" className="me-2" />
            Two-step authentication code
          </label>
          <p className="grey-text">
            Enter your 2FA code from your Authentication App.
          </p>
        </Col>
        <CodeInput code={data.two_step} updateCode={updateTwoStep} errorFields={errorFields} submit={submit} />
        {0 < errorFields.length && 0 < errorFields.filter(field => ['two_step'].includes(field.key)).length ? <Col className="mt-3">
          <Alert variant="danger" className="p-2 m-0">
            <strong className="bold me-3">
              <FontAwesomeIcon type="solid" icon="exclamation-triangle" className="me-1" />
              Error
            </strong>
            {errorFields.filter(field => 'two_step' === field.key)[0].message}
          </Alert>
        </Col> : ''}
        <Col xs={12} className="mt-3">
          <Button onClick={submit} disabled={Object.keys(data.two_step).map((k) => data.two_step[k]).join('').length !== 6 || loading} variant="success" className="w-100">
            <FontAwesomeIcon icon={loading ? 'spinner' : 'chevron-right'} className={'me-2 ' + (loading ? 'fa-spin' : '')} />
            {loading ? 'Loading...' : 'Verify'}
          </Button>
        </Col>
      </Row> : <Row>
        {0 < errorFields.length && 0 < errorFields.filter(field => ['login'].includes(field.key)).length ? <Col className="mt-3 mb-3">
          <Alert variant="danger" className="p-2 m-0">
            <strong className="bold me-3">
              <FontAwesomeIcon type="solid" icon="exclamation-triangle" className="me-1" />
              Error
            </strong>
            {errorFields.filter(field => 'login' === field.key)[0].message}
          </Alert>
        </Col> : ''}
        <Col xs={12}>
          <EmailField
            data={data}
            updateField={updateField}
            errorFields={errorFields}
            onSubmit={submit}
          />
          <FormCheck
            id={'remember_me'}
            name="remember_me"
            type={'switch'}
            onChange={toggleRememberMe}
            checked={data.remember_me}
            label={'Remember me'}
            className={'grey-text mt-1 text-darken-2'}
            onSubmit={submit}
          />
        </Col>
        <Col xs={12} className="mt-3">
          <PasswordField
            value={data.password}
            updateField={updateField}
            errorFields={errorFields}
            onSubmit={submit}
          />
          <button type={'button'} onClick={() => openForgotPassword()} className="ms-2 float-end transparent border-0 text-primary">
            Forgot password?
          </button>
        </Col>
        <Col xs={12} className="mt-3">
          <Button type={'submit'} onClick={submit} disabled={loading} variant="success" className="w-100">
            <FontAwesomeIcon icon={loading ? 'spinner' : 'sign-in'} className={'me-2 ' + (loading ? 'fa-spin' : '')} />
            {loading ? 'Loading...' : 'Log In'}
          </Button>
        </Col>
        <Col xs={12} className="mt-2 grey-text text-darken-2 text-center">
          Don't have an account?
          <button type={'button'} onClick={openRegister} className="ms-2 transparent border-0 text-primary">
            Register
          </button>
        </Col>
      </Row> }
    </div>
  </div>;
}

export default Login;
