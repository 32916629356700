const MetaData = (path) => {
  const data = {
    '/': {
      title: `Wholesale Packaging | Packaging Supplies | Packaging Suppliers | Cardboard Boxes and Cartons`,
      description: 'Wholesale Packaging Ltd is one of the largest packaging materials supplier in the UK. Founded in 1954!',
    },
    'Home': {
      title: `Wholesale Packaging | Packaging Supplies | Packaging Suppliers | Cardboard Boxes and Cartons`,
      description: 'Wholesale Packaging Ltd is one of the largest packaging materials supplier in the UK. Founded in 1954!',
    },
    '/account': {
      title: `My Account | Wholesale Packaging`,
      description: 'Manage your online account, orders, addresses, payment methods, and product reviews.',
    },
    '/notifications': {
      title: `Notifications | Wholesale Packaging`,
      description: 'View your account notifications.',
    },
    '/about': {
      title: `About Wholesale Packaging`,
      description: 'Founded in 1954, Wholesale Packaging Ltd is one of the largest packaging materials suppliers in the UK.',
    },
    '/contact': {
      title: `Contact Wholesale Packaging`,
      description: 'Get in touch with our team today with your questions about packaging supplies.',
    },
    '/legal/privacy': {
      title: `Privacy Policy | Wholesale Packaging`,
      description: 'Wholesale Packaging is committed to treating your personal information with a high degree of care. Your personal information will never be shared or sold to third parties.',
    },
    '/legal/terms': {
      title: `Terms & Conditions | Wholesale Packaging`,
      description: 'Read our terms & conditions to find out more about how we use your personal information.',
    },
    '/legal/cookie': {
      title: `Cookie Policy | Wholesale Packaging`,
      description: 'When visiting our website, cookies are stored on your computer. Cookies are small text files that are stored by your browser on your device to save certain information.',
    },
    '/search': {
      title: `Search | Wholesale Packaging`,
      description: 'Search our website to find all of your packaging supplies / materials.',
    },
    '/login': {
      title: `Login | Wholesale Packaging`,
      description: 'Log in to your Wholesale Packaging account.',
    },
    '/register': {
      title: `Register | Wholesale Packaging`,
      description: 'Create an account with Wholesale Packaging.',
    },
    '/feedback': {
      title: `Feedback | Wholesale Packaging`,
      description: 'Let us know your feedback, suggestions or ideas so we can improve our website to give you a better experience.',
    },
  };

  return data[path];
};

export default MetaData;
