import Request from '../system/Request';

const Wishlist = {
  add: (productId) => {
    return new Promise((resolve, reject) => {
      (new Request()).post('/api/wishlist/add', JSON.stringify({
        productId: productId,
      }), {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },

  remove: (productId) => {
    return new Promise((resolve, reject) => {
      (new Request()).post('/api/wishlist/remove', JSON.stringify({
        productId: productId,
      }), {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },
};

export default Wishlist;
