import Account from './api/Account';
import Cart from './api/Cart';
import Category from './api/Category';
import Product from './api/Product';
import Notify from './api/Notify';
import Search from './api/Search';
import Wishlist from './api/Wishlist';
import GovUK from './api/GovUK';

const Api = {
  Account: Account,
  Cart: Cart,
  Category: Category,
  Product: Product,
  Notify: Notify,
  Search: Search,
  Wishlist: Wishlist,
  GovUK: GovUK
};

export default Api;
