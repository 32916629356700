import React, { useRef, useState, useEffect } from 'react';
import { useAppContext } from '../../contexts/App';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import FontAwesomeIcon from '../FontAwesomeIcon';
import EmailField from '../Form/Fields/EmailField';
import PasswordField from '../Form/Fields/PasswordField';
import FirstNameField from '../Form/Fields/FirstNameField';
import LastNameField from '../Form/Fields/LastNameField';
import RegisterFormState from '../../data/form/RegisterFormState';
import Api from '../../Api';

import styles from './styles.module.scss';

const Register = () => {
  const { appState, dispatchApp } = useAppContext();
  const [loading, setLoading] = useState(false);
  const hasLoaded = useRef(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [data, setData] = useState(RegisterFormState);
  const [errorFields, setErrorFields] = useState([]);

  useEffect(() => {
    if (hasLoaded.current) return;
    hasLoaded.current = true;
  }, []);

  const openLogin = () => {
    appState.setDialogState('login', true);
    appState.setDialogState('register', false);

    dispatchApp({ ...appState });
  };

  const updateField = (e) => {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;

    setData(newData);
    setErrorFields([]);
  };

  const updateFieldById = (id, value) => {
    const newData = { ...data };
    newData[id] = value;

    setData(newData);
    setErrorFields([]);
  };

  const submit = () => {
    setLoading(true);
    Api.Account.register(data).then((response) => {
      setLoading(false);

      if (undefined === response.violations) {
        setSuccessMessage(<>
          <div className={'mb-2 bold'}>Account created successfully!</div>
          Please <span className={'bold text-decoration-underline'}>activate</span> your account by clicking the link in the email we sent you.
          If you can't see the email, please check your spam / junk folders.
        </>);
      } else {
        response.violations.forEach((violation) => {
          if ('This value should not be blank.' === violation.message) return;

          setErrorFields([{
            key: violation.propertyPath,
            message: 'This value is already used.' === violation.message ? 'Error registering account' : violation.message
          }]);
        })
      }
    }).catch((e) => {
      setLoading(false);
      if (e?.body?.fields) {
        let newErrorFields = [];
        e.body.fields.forEach((field) => {
          newErrorFields.push({
            key: field.key,
            message: field.message
          });
        });
        setErrorFields(newErrorFields);
      } else {
        setErrorFields([{
          key: 'register',
          message: 'An error occurred, please try again!'
        }])
      }
    });
  };

  return <form id={'register_dialog'} onSubmit={(e) => e.preventDefault()} autoComplete={'off'}>
    {successMessage ? <Row>
      <Col className={'mt-3'}>
        <Alert variant={'success'}>
          {successMessage}
        </Alert>
      </Col>
    </Row> : <Row>
    {0 < errorFields.length && 0 < errorFields.filter(field => ['register'].includes(field.key)).length ? <Col xs={12} className="mt-3 mb-3">
      <Alert variant="danger" className="p-2 m-0">
        <strong className="bold me-3">
          <FontAwesomeIcon type="solid" icon="exclamation-triangle" className="me-1" />
          Error
        </strong>
        {errorFields.filter(field => 'register' === field.key)[0].message}
      </Alert>
    </Col> : ''}
      <Col xs={12} md={6} className="mt-3">
        <FirstNameField
          data={data}
          updateField={updateFieldById}
          errorFields={errorFields}
          onSubmit={submit}
        />
      </Col>
      <Col xs={12} md={6} className="mt-3">
        <LastNameField
          data={data}
          updateField={updateFieldById}
          errorFields={errorFields}
          onSubmit={submit}
        />
      </Col>
      <Col xs={12} className="mt-3">
        <EmailField
          data={data}
          updateField={updateFieldById}
          errorFields={errorFields}
          onSubmit={submit}
        />
      </Col>
      <Col xs={12} md={6} className="mt-3">
        <PasswordField
          value={data.password}
          updateField={updateFieldById}
          errorFields={errorFields}
          onSubmit={submit}
        />
      </Col>
      <Col xs={12} md={6} className="mt-3">
        <PasswordField
          id={'confirm_password'}
          label={'Confirm Password'}
          placeholder={'Confirm your password'}
          value={data.confirm_password}
          updateField={updateFieldById}
          errorFields={errorFields}
          onSubmit={submit}
        />
      </Col>
      <Col xs={12} className="mt-3">
        <div className={'d-flex'}>
          <fieldset className={'me-3'} style={{ width: '40px' }}>
            <input
              type="checkbox"
              id="confirm"
              name="confirm"
              value={true}
              onChange={updateField}
              className={errorFields.includes('confirm') ? styles.input__error : ''}
              autoComplete={'off'}
              required
            />
          </fieldset>
          <label htmlFor="confirm" className="mb-1 w-75p">
            <span className={'text-primary bolder'}>Accept Terms &amp; Conditions and Privacy Policy</span>
            <p className={'text-dark fs-8'}>
              Please click the checkbox to confirm that you agree to our <Link to={'/legal/terms'}>terms & conditions</Link> and <Link to={'/legal/privacy'}>privacy policy</Link>.
            </p>
          </label>
        </div>
        {0 < errorFields.filter(field => 'confirm' === field.key).length ? <div className={'fs-8 red-text'}>
          <FontAwesomeIcon icon={'exclamation-triangle'} className={'me-2'}/>
          {errorFields.filter(field => 'confirm' === field.key)[0].message}
        </div> : ''}
      </Col>
      <Col xs={12} className="mt-3">
        <Row>
        <Col>
            <Button type={'submit'} onClick={submit} disabled={loading} variant="success" className="w-100">
              <FontAwesomeIcon icon={loading ? 'spinner' : 'check'} className="me-2"/>
              {loading ? 'Loading...' : 'Create account!'}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col className="mt-2 text-dark text-center">
            Already have an account?
            <button type={'button'} onClick={openLogin} className="ms-2 transparent border-0 text-primary">
              Login
            </button>
          </Col>
        </Row>
      </Col>
    </Row>}
  </form>;
};

export default Register;
