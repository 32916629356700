import React, { lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Providers from './system/Providers';
import AuthenticatedRoutes from './system/AuthenticatedRoutes';
import ScrollToTop from './system/ScrollToTop';

import Layout from './pages/Layout';
import Loading from './pages/Loading';

// Basic Pages
const Home = lazy(() => import('./pages/Home'));
const About = lazy(() => import('./pages/About'));
const Contact = lazy(() => import('./pages/Contact'));

// Information Pages
// const Delivery = lazy(() => import('./pages/Delivery'));
const Delivery = lazy(() => import('./pages/Delivery'));
const Payment = lazy(() => import('./pages/Payment'));
const Selling = lazy(() => import('./pages/Selling'));
const RoyalMail = lazy(() => import('./pages/RoyalMail'));

// Catalogue Pages
const Shop = lazy(() => import('./pages/Shop'));
const Category = lazy(() => import('./pages/Category'));
const EcoFriendly = lazy(() => import('./pages/Category/EcoFriendly'));
const Product = lazy(() => import('./pages/Product'));
const Search = lazy(() => import('./pages/Search'));
const Cart = lazy(() => import('./pages/Cart'));
// const Checkout = lazy(() => import('./pages/Checkout'));

// Legal Pages
const Legal = lazy(() => import('./pages/Legal'));
const LegalCookie = lazy(() => import('./pages/Legal/Cookie'));
const LegalReturns = lazy(() => import('./pages/Legal/Returns'));
const LegalTerms = lazy(() => import('./pages/Legal/Terms'));
const LegalPrivacy = lazy(() => import('./pages/Legal/Privacy'));
const LegalWebsiteSecurity = lazy(() => import('./pages/Legal/WebsiteSecurity'));

// Account Pages
const Account = lazy(() => import('./pages/Account'));
const AccountLogin = lazy(() => import('./pages/Account/Login'));
const AccountRegister = lazy(() => import('./pages/Account/Register'));
const AccountWishlist = lazy(() => import('./pages/Account/Wishlist'));
// const AccountOrder = lazy(() => import('./pages/Account/Order'));
// const AccountAddress = lazy(() => import('./pages/Account/Address'));
const AccountNotification = lazy(() => import('./pages/Account/Notification'));

// Error Pages
const NoPage = lazy(() => import('./pages/NoPage'));

function App() {
  return <Providers>
    <BrowserRouter
      future={{
        v7_startTransition: true,
        v7_relativeSplatPath: true,
      }}
    >
      <ScrollToTop />
      <React.Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />

            <Route element={<AuthenticatedRoutes />}>
              <Route path="/account" element={<Account />} />
              <Route path="/account/notifications" element={<AccountNotification />} />
              <Route path="/account/wishlist" element={<AccountWishlist />} />
            </Route>

            <Route path='/register' element={<AccountRegister />} />
            <Route path="/login" element={<AccountLogin />} />
            <Route path="/account/activation/failed" element={<AccountLogin />} />
            <Route path="/account/activation/activated" element={<AccountLogin />} />
            <Route path="/account/activation/complete" element={<AccountLogin />} />

            <Route path="/search" element={<Search />} />
            <Route path="/search/*" element={<Search />} />

            <Route path="/shop" element={<Shop />} />
            <Route path="/c/eco-friendly" element={<EcoFriendly />} />
            <Route path="/c/*" element={<Category />} />
            <Route path="/p/*" element={<Product />} />
            <Route path="/cart" element={<Cart />} />
            {/*<Route path="/checkout" element={<Checkout />} />*/}

            <Route path='/about' element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/delivery" element={<Delivery />} />
            <Route path="/royal-mail-changes" element={<RoyalMail />} />
            <Route path="/feedback" element={<Contact />} />
            <Route path="/report" element={<Contact />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="/selling-to-us" element={<Selling />} />

            <Route path="/legal" element={<Legal />} />
            <Route path="/legal/cookie" element={<LegalCookie />} />
            <Route path="/legal/privacy" element={<LegalPrivacy />} />
            <Route path='/legal/returns' element={<LegalReturns />} />
            <Route path="/legal/terms" element={<LegalTerms />} />
            <Route path="/legal/website-security" element={<LegalWebsiteSecurity />} />

            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </React.Suspense>
    </BrowserRouter>
  </Providers>;
}

export default App;
