import Request from '../system/Request';

const Search = {
  get: (data, isTwoStep) => {
    const body = {
      keyword: data.keyword,
      page: data.page ?? 1,
      numOfRecords: data.recordsPerPage ?? 10,
    };

    return new Promise((resolve, reject) => {
      (new Request()).post('/api/search', JSON.stringify(body), {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }, true)
        .then(data => resolve(data))
        .catch(e => reject(e));
    });
  },
};

export default Search;
