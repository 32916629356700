import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FontAwesomeIcon from '../FontAwesomeIcon';
import TextField from '../Form/Fields/_Types/TextField';

const HeaderSearch = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');

  const onSubmit = (e) => {
    console.log('searchValue', searchValue);
    navigate(`/search?q=${searchValue}`);
  };

  return <div className={'d-flex flex-row flex-fill align-items-center'}>
    <div className={'d-flex flex-column'}>
      <FontAwesomeIcon icon={'search'} className={'me-3'} />
    </div>
    <div className={'d-flex flex-fill flex-column'}>
      <TextField
        value={searchValue}
        placeholder={'Search for products'}
        className={'w-100'}
        onChange={(id, value) => setSearchValue(value)}
        onSubmit={onSubmit}
      />
    </div>
  </div>;
};

export default HeaderSearch;
